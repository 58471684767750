import Vue from 'vue'
import VueRouter from 'vue-router'
import {Message} from 'element-ui'

Vue.use(VueRouter)

/**
 * 普通路由
 */
export const defaultRoutes  = [
  {
    path: '/',
    name: 'Guidance',
    redirect:'/guidance',
    component: () => import('../views/guidance/index.vue'),
    meta:{
      isLogin:false
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login/index.vue'),
    meta:{
      isLogin:false
    }
  },
  {
    path: '/forget',
    name: 'Forget',
    component: () => import('../views/forget/index.vue'),
    meta:{
      isLogin:false
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/register/index.vue'),
    meta:{
      isLogin:false
    }
  },
  {
    path: '/layer',
    name: 'Layer',
    component: () => import('../views/layer/index.vue'),
    children:[
      {
        path: '/guidance',
        name: 'Guidance',
        component: () => import('../views/guidance/index.vue'),
        meta:{
          isLogin:false
        }
      },
      {
        path: '/home',
        name:'Home',
        component: () => import('../views/home/index.vue'),
        meta:{
          isLogin:false
        }
      },
      {
        path: '/buying',
        name:'Buying',
        component: () => import('../views/buying/index.vue'),
        meta:{
          isLogin:false
        }
      },
      {
        path: '/order',
        name:'Order',
        component: () => import('../views/order/index.vue'),
        meta:{
          isLogin:false
        }
      },
      {
        path: '/taobao',
        name:'Taobao',
        component: () => import('../views/taobao/index.vue'),
        meta:{
          isLogin:false
        }
      }, 
      {
        path: '/about',
        name:'About',
        component: () => import('../views/about/index.vue'),
        meta:{
          isLogin:false
        }
      },
      {
        path: '/my',
        name:'My',
        component: () => import('../views/my/index.vue'),
        meta:{
          isLogin:true
        }
      },
      {
        path: '/agreement',
        name:'Agreement',
        component: () => import('../views/agreement/index.vue'),
        meta:{
          isLogin:false
        }
      },
      {
        path: '/detail',
        name:'Detail',
        component: () => import('../views/detail/index.vue'),
        meta:{
          isLogin:true
        }
      }
    ],
  },
  {
    path: '/404',
    name: 'Notfount',
    component: () => import('../views/404.vue')
  },
  {
    path: '*',
    name: 'Notfount',
    component: () => import('../views/404.vue')
  }
]



const router = new VueRouter({
  // mode:'history',// 国内地址路由
  mode:'hash',// 国外地址路由
  routes:defaultRoutes 
})

/**
 * 路由守卫
 */
router.beforeEach((to,from,next)=>{
  if(to.matched.some(res=>res.meta.isLogin)){
    let token = JSON.parse(sessionStorage.getItem('token'))
    if (token) {
      next();
    }else{
      Message.error('请登录后操作');
     
    }
  }else{
    next()
  }
})

export default router

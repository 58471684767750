import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    Token:'',
    userInfo:{},
    routes: [],
    // 全部已经格式化过后的树形权限
    allScope:[],
    allTree:[],
    // 本地
    // baseUrl:'http://www.jiaguo.com/',
    // 国内线上
    // baseUrl:'http://121.196.103.197:3333/',
    // 国外线上
    // baseUrl:'https://xiao.ecgbuy.com/',
    // 国外线上新地址
    baseUrl:'https://airseascargo.com/',
    pageArray:[10,50,100,200,500,1000]
  },
  mutations: {
    SET_ROUTES(state, routes) {},
    SET_USERINFO(state, userdata){},
    SET_SCOPE(state, all){
      state.allScope = all;
    },
    SET_ALLSCOPE(state, all){},
  },
  actions: {
  },
  modules: {
  }
})
